<template>
  <div class="col-md-12 px-0">
    <hr />
    <label class="label_default">Vídeo Youtube</label>
    <div class="input-group">
      <div class="input-group-prepend">
        <div class="input-group-text">https://www.youtube.com/watch?v=</div>
      </div>
      <input
        type="text"
        class="input_default form-control"
        v-on:input="$emit('update:videoUrl', $event.target.value)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["videoUrl"],
  data() {
    return {
      link: ""
    };
  },
  watch: {
    link: function() {
      this.$emit("update:videoUrl", this.link);
    }
  }
};
</script>

<style scoped>
.titulo_dash {
  color: #2474b2;
  font-weight: 500;
}
.input_default {
  height: auto;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.input-group-text {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  font-size: 10px;
  background-color: #868686;
  color: #ffff;
  font-weight: 500;
}
.label_default {
  color: #2474b2 !important;
  font-weight: 500;
}
</style>